<template>
	<v-app id="main-app">
	<v-container class="fill-height" fluid>
		<v-card class="mx-auto px-10 pb-9 login-card" width="550px">
			<span class="my-3">
<!--				<button @click="language('en')" class="mx-1 pa-0"><country-flag country="us"/></button>-->
<!--				<button @click="language('es')" class="ma-0 pa-0"><country-flag country="mx"/></button>-->
        <button @click="language('en')" style="color: #6600FF;" class="mx-1 pa-0">{{ $t("en") }}</button>
			<button @click="language('es')" style="color: #6600FF;" class="mx-1 pa-0">{{ $t("es") }}</button>
			</span>
			<v-card-title class="justify-center pt-12"><img v-bind:src="logo.src" v-bind:style="{ width: logo.width, height: logo.height }"></v-card-title>
			<v-card-subtitle class="text-center py-1 title-login" style="font-size: 20px !important">{{$t('welcomeToVitalCheckUps')}}</v-card-subtitle>
			<v-card-subtitle class="text-center title-login primary--text pt-0" style="font-size: 16px !important">{{$t('moto')}}</v-card-subtitle>
			<alert-list-auth />
			<v-card-text class="text-center">
				<v-form ref="form1" v-if="showForm === 1" @submit.prevent="login">
					<v-text-field
                        v-model="email"
						ref="email"
						v-bind:label="$t('email')"
						name="email"
						type="email"
                        prepend-inner-icon="mdi-email mr-4 mb-1"
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; font-family: 'Catamaran', serif !important;"
                        :rules="[rules.emailRequired, rules.email]"
                        @keyup.enter="clickButton"
                    />
					<v-text-field
                        v-model="password"
						v-bind:label="$t('password')"
						name="password"
                        prepend-inner-icon="mdi-lock mr-4 mb-1"
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; font-family: 'Catamaran', serif !important;"
                        :rules="[rules.passwordRequired]"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:autocomplete="showPassword ? 'off' : 'current-password'"
						:type="showPassword ? 'text' : 'password'"
                        @click:append="showPassword = !showPassword"
                        @keyup.enter="clickButton"
                    />
				</v-form>
				<v-form ref="form2" v-if="showForm === 2" @submit.prevent="resetPassword">
                    <v-input v-if="!emailSent" class="primary--text receive-email-text">{{$t('enterYourEmailToRecieveResetPasswordLink')}}</v-input>
                    <v-input v-else class="primary--text receive-email-text">{{$t('weSentAnResetLink')}} <b class="ml-2">{{ this.email }}</b></v-input>
					<v-text-field
                        v-if="!emailSent"
                        v-model="email"
						v-bind:label="$t('email')"
						name="email"
						type="email"
                        prepend-inner-icon="mdi-email-send mr-4 mb-1"
                        background-color="#E8E8E8"
                        rounded
                        outlined
                        style="border-radius: 12px !important; font-family: 'Catamaran', serif !important;"
                        :rules="[rules.emailRequired, rules.email]" />
				</v-form>
			</v-card-text>
			<v-card-actions class="pb-10">
				<v-btn text v-if="showForm !== 2" @click="showForm = 2" class="ma-2 title-forgot-password">{{$t('forget')}} {{$t('password')}}</v-btn>
				<v-btn text v-if="showForm !== 1 && !emailSent" @click="showForm = 1" class="ma-2 title-forgot-password">{{$t('signin')}}</v-btn>
                <v-btn text v-if="showForm === 2 && emailSent" @click="showform" class="ma-2 title-forgot-password">
                    <v-icon class="primary--text mr-3">mdi-arrow-left-bottom</v-icon>
                    <span>{{$t('backToSignIn')}}</span>
                </v-btn>
				<v-spacer />
				<v-btn v-if="!emailSent" color="primary" large class="ma-0 pa-1" style="border-radius: 20px; font-size: 12px;" :loading="loading" :disabled="loading" @click="loader = 'loading'">
					<span>{{ action }}</span>
				</v-btn>
			</v-card-actions>
			<v-footer color="transparent" class="px-10" absolute padless>
				<v-row dense>
					<v-col class="text-center black--text ma-0 pa-0" cols="12">
						<span style="font-size: 11px; color: grey">{{$t('signing-in-to-this-webapp')}}</span>
						<span style="font-size: 11px; cursor: pointer;" class="primary--text" @click="redirectToWebsiteTerms"> {{$t('website-terms-of-use')}} </span>
					</v-col>
				</v-row>
				<v-row dense class="ma-0 pa-0">
					<v-col class="text-center black--text mt-0 pt-0" cols="12">
						<span style="font-size: 11px; color: grey"> {{$t('acknowledge')}}</span>
						<span class="primary--text" style="font-size: 11px; color: grey; cursor: pointer;" @click="redirectToPrivacyTerms"> {{$t('privacy-policy')}}</span>
					</v-col>
				</v-row>
				<v-row dense class="ma-0 pa-0">
					<v-col class="text-center black--text mt-0 pt-0" cols="12">
						<span style="font-size: 11px; color: grey"> {{$t('delete-request-account')}}</span>
						<span class="primary--text" style="font-size: 11px; color: grey; cursor: pointer;" @click="redirectToRequestDeleteAccount"> {{$t('delete-requestlink-account')}}</span>
					</v-col>
				</v-row>
			</v-footer>
		</v-card>
	</v-container>
	</v-app>
</template>

<script>
import AlertListAuth from '../Client/components/AlertListAuth.vue';
import logoBridge from '../../../public/logo_bridge.png';
import Login from '../../data/models/Login';
import { mapGetters, mapState } from 'vuex';
// import CountryFlag from 'vue-country-flag';

const { LOGIN_LOGO } = require('@/themes/VitalCheckups/image-paths');

export default {
	components: {
		AlertListAuth,
		// CountryFlag,
	},
	data () {
		return {
			loader: null,
			loading: false,
			logo: LOGIN_LOGO,
			showForm: 1,
			showPassword: false,
			logo_bridge: logoBridge,
			langs: [{ id: 'en', name: 'English' }, { id: 'es', name: 'Español' }],
			rules: {
				emailRequired: value => !!value || this.$t('emailRequiredMaterial'),
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || 'Enter a valid e-mail address';
				},
				passwordRequired: value => !!value || this.$t('passwordEnterPassword'),
			},
			email: '',
			password: '',
			emailSent: false,
		};
	},
	computed: {
		...mapGetters({ delay: 'authentication/getDelay' }),
		...mapState({
			userDetails: (state) => state.authentication.userData,
		}),
		action () {
			switch (this.showForm) {
			case 1: return this.$t('signin');
			case 2: return this.$t('submit');
			default: return '';
			}
		},
	},
	watch: {
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];
				await this.clickButton()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});
				this.loader = null;
			}
		},
	},
	mounted () {
		if (this.$store.getters['authentication/hasStoredSession']) {
			this.$store.dispatch('authentication/doLogout');
			this.$store.commit('alerts/clear');
			localStorage.clear();
		}
		this.$i18n.locale = 'en';
	},
	methods: {
		language (val) {
			this.$cookies.set('language', val, '6m');
			this.$i18n.locale = this.$cookies.get('language');
		},
		redirectToWebsiteTerms () {
			window.open('https://www.vitalcheckups.com/vitakcheckups_website_terms_of_use_rw_4_28_2022.html', '_blank');
		},
		redirectToPrivacyTerms () {
			window.open('https://www.vitalcheckups.com/vitalcheckups_website_privacy_policy_rw__4_28_2022.html', '_blank');
		},
		redirectToRequestDeleteAccount () {
			window.location.href = '/accountdeletionrequest';
		},
		showform () {
			this.showForm = 1;
			this.emailSent = false;
		},
		async login () {
			const login = new Login({
				username: this.email,
				password: this.password,
			});
			await this.$store.dispatch('authentication/doLogin', login)
				.then(async () => {
					switch (this.$store.getters['authentication/getRole']) {
					case 'SYSTEM_ADMIN':
						this.$router.push('/organizations');
						break;
					case 'ORGANIZATION_ADMIN':
						this.$router.push('/organizations');
						break;
					case 'HOSPITAL_ADMIN':
						this.$router.push('/medicalstaff');
						break;
					default:
						this.$router.push('/dashboard');
						await this.$store.dispatch('setupWebSockets');
						break;
					}
					var tempcookie;
					if (this.$cookies.get('language') === null) {
						switch (this.userDetails.language) {
						case 'ENG':
							this.$cookies.set('language', 'en', '6m');
							break;
						case 'ESP':
							this.$cookies.set('language', 'es', '6m');
							break;
						}
						this.$i18n.locale = this.$cookies.get('language');
					} else {
						switch (this.$cookies.get('language')) {
						case 'en':
							tempcookie = 'ENG';
							break;
						case 'es':
							tempcookie = 'ESP';
							break;
						}
						this.$i18n.locale = this.$cookies.get('language');
						if (tempcookie !== this.userDetails.language) {
							var body = {
								Language: tempcookie,
							};
							this.$store.dispatch('users/changemyLanguage', body);
						}
					}
				})
				.catch(() => {
					this.$store.commit('alerts/add', {
						auth: true,
						type: 'error',
						color: '#D30202',
						// message: err.response.data.msg,
						message: this.$t('failedLogin'),
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				});
		},
		async resetPassword () {
			const body = {
				email: this.email,
			};
			await this.$store.dispatch('users/sendValidationLinkByEmail', body)
				.then((res) => {
					if (res.resFlag) {
						this.emailSent = true;
					}
				})
				.catch(() => {
					this.$store.commit('alerts/add', {
						auth: true,
						type: 'error',
						color: '#D30202',
						// message: 'Could not sent validation link',
						message: this.$t('failedValidation'),
					});
					this.$store.dispatch('alerts/clearWithDelay', this.delay);
				});
		},
		clickButton () {
			switch (this.showForm) {
			case 1: return this.login();
			case 2: return this.resetPassword();
			}
		},
	},
};
</script>
<style scoped>
    #main-app {
        background: linear-gradient(#E8E8E8, #d6d6d6);
    }
</style>
